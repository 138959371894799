import { useState, useEffect } from "react";

import { reportError } from "../../../functions/sentry";
import apiCall from "../../utilities/auth/apiCall";
import { assessmentNextContent } from "../../utilities/auth/authApi";
import sendAssessmentResponses from "../../../functions/sendAssessmentResponses";
import alertMessage from "../../utilities/alertMessage";

import AssessmentInstruction from "../helpers/assessmentInstruction";
import ReadPassage from "../read/readPassage";
import Modal from "../../utilities/modal";
import SaveModalBody from "../helpers/saveModalBody";


function AssessmentOpinion(props) {

  // Define variables
  const [modal, setModal] = useState(null);
  const [step, setStep] = useState(0);
  const [questions, setQuestions] = useState([]);
  const [questionStep, setQuestionStep] = useState(0);
  const [responses, setResponses] = useState([]);
  const handleSteps = props.handleSteps;
  const instructions = props.instructions;
  const config = props.config;
  const opinionQuestionCount = config.opinionQuestionCount;
  const questionInitial = config.questions;
  const retryPossible = config.retryCount;
  const typeWord = 'Question';


  // Component load actions
  useEffect(() => {
    setQuestions(questionInitial);
  }, []);


  // Define functions
  function handleNextAction(responseData) {

    // Save the repsonse data
    const updatedResponses = [...responses, responseData];
    setResponses(updatedResponses);


    // Handle the last passage
    if( opinionQuestionCount === (questionStep + 1) ) {
      saveData(updatedResponses);
    } else {

      // Ensure there is a score
      const score = (responseData?.scoreTwo?.overall ) ? responseData.scoreTwo.overall.percent : 65;

      // Get the next content
      const payload = {
        code: config.code,
        type: 'question',
        score: score,
        previousIds: questions.map((p) => p.id)
      }

      apiCall(assessmentNextContent, payload).then((response) => {
        if( response.success ) {

          // Add the new passage to the passages array
          const updatedQuestions = [...questions, response.data.content];
          setQuestions(updatedQuestions);

          // Move to the next passage
          setQuestionStep(questionStep + 1);
          logProgress({questionStep: questionStep + 2});

        } else {
          reportError(response.error);
          alertMessage(response.error, 'error');
        }

      });
    }
  }

  function saveData(updatedResponses) {
    setModal(<Modal body={<SaveModalBody />} />);

    const payload = {
      type: 'opinion',
      code: config.code,
      responses: updatedResponses
    }

    sendAssessmentResponses(payload).then((response) => {
      if( response.success ) {
        setTimeout(() => {
          logProgress({section: 5, step: 0});
        }, 1000);
      } else {
        reportError(response.error);

        setModal(null);
        alertMessage(response.error, 'error');
      }
    });
  }

  function logProgress(data) {
    if( data.step ) {
      setStep(data.step);
    }

    handleSteps(data);
  }


  // Screen components
  let bodyContent;
  let passageCountElement = <div class='assess-passage-number-container'>&nbsp;</div>;

  if( step === 0 ) {
    bodyContent = <AssessmentInstruction logProgress={logProgress} instructions={instructions} nextStep={1} questionStep={1} />;
  } else if( step === 1 ) {

    passageCountElement = <div class='assess-passage-number-container'>{typeWord} {questionStep+1} of {opinionQuestionCount}</div>;

    bodyContent = (
      <ReadPassage
        passage={questions[questionStep]}
        nextAction={handleNextAction}
        instructionRecord={instructions.record}
        instructionExtra={instructions.question}
        instructionReview={instructions.review}
        timerSpeak={config.opinionSpeakTimer}
        timerReview={config.opinionReviewTimer}
        retryPossible={retryPossible}
        scoreType={'conversation'}
      />
    );
  }


  return (
    <>
      <h1>Opinion</h1>
      {passageCountElement}
      {bodyContent}
      {modal}
    </>
  );
}

export default AssessmentOpinion;