import React from "react";
import { useEffect } from 'react';
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

import '../../styles/profileSettings.css';

// import { Toaster } from 'react-hot-toast';
import toast from 'react-hot-toast';
import alertMessage from "../utilities/alertMessage";
import { reportError } from "../../functions/sentry";

import Nav from "../utilities/nav";
import HeaderMain from "../utilities/headermain";
import BottomNav from "../utilities/bottomnav";
import ProfileSubNav from "./profilesubnav";

import fadeInMainBody from "../../functions/fadeinmainbody";
import { getStripePortalUrl } from "../utilities/auth/authApi";


function ProfileSubscription() {

  const hasPremiumSubscription = useSelector((state) => state.profile.hasPremiumSubscription);
  const subscriptionName = useSelector((state) => state.profile.subscriptionName);

  useEffect(() => {
    fadeInMainBody();
  }, []);


  function openCustomerPortal(event) {

    const target = event.target;
    if( target ) { target.classList.add('is-loading') }

    getStripePortalUrl().then((response) => {
      if( response && response.data && response.data.url ) {
        // window.open(response.data.url, "_blank");
        window.location.href = response.data.url;
      } else {
        // Handle error
        toast.error('Sorry, there was an error opening the customer portal. Please contact support@flowspeak.io');
        target.classList.remove('is-loading')
      }
    }).catch((error) => {
      reportError(error);
      alertMessage('Sorry, there was an error. Please contact support@flowspeak.io for assistance.', 'error');
    });
  }


  let subscriptionContent;
  if( hasPremiumSubscription ) {

    let manageButton;
    if( ["Monthly", "6 Months", "Yearly"].includes(subscriptionName) ) {
      manageButton = <button type='button' class='button' onClick={openCustomerPortal}>Manage my subscription</button>;
    }

    subscriptionContent = (
      <div class='subscription-description'>

        <p>Premium Membership</p>
        <ul>
          <li>Unlimited access to all Premium lessons</li>
          <li>Access to Premium courses</li>
          <li>Access to IELTS speaking prep courses</li>
          <li>Advanced speech scoring</li>
          <li>Professional feedback</li>
          <li>1 Speaking Assessment per month</li>
          <li>Save your favorite lessons</li>
        </ul>

        {manageButton}

      </div>
    );
  } else {
    subscriptionContent = (
      <div class='subscription-description'>

        <p>Community Membership</p>
        <ul>
          <li>3 lessons per week</li>
          <li>Unlimited response retries</li>
          <li>Community feedback</li>
        </ul>

        <p class='small'>Upgrade your membership to access premium content and features.</p>
        <Link to="/packages" title='Upgrade to a premium subscription' class='button'>Upgrade</Link>

      </div>
    );
  }




  return (
    <div class='wrapper gray-background'>
      {/* <Toaster /> */}
      <Nav />

      <div class='main-body invisible'>
        <HeaderMain title="Profile" />
        <div class='main-body-content profile'>

          <ProfileSubNav />

          <div class='profile-input-wrapper'>
            <label>Subscription</label>
            {/* <div class='profile-input'>
            </div> */}
            {subscriptionContent}
          </div>

        </div>
      </div>
      <BottomNav />
    </div>
  )
}

export default ProfileSubscription;