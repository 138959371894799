function AssessmentHeader(props) {

  // Define variables
  const showSeeMoreElement = props.showSeeMoreElement;
  const assessment = props.assessment;
  const date = assessment.date;
  const status = assessment.status;
  const complete = (status === 'Complete');
  const scoreCefrSubLevel = assessment.scoreCefrSubLevel;
  const scoreOverall = assessment.scoreOverall;
  const code = assessment.code;


  // Screen components
  let statusElement;
  let seeMoreElement;
  let bodyElement;

  if( complete ) {

    let seeMoreElement;
    if( showSeeMoreElement ) {
      seeMoreElement = <p class='my-assess-small'>Tap to see details</p>;
    }

    bodyElement = (
      <div>
        <p class='my-assess-cefr-label'>CEFR</p>
        <p class='my-assess-cefr'>{scoreCefrSubLevel}</p>
        <p class='my-assess-small' style={{marginTop: '10px', marginBottom: '30px'}}>( {scoreOverall} / 100 )</p>

        <p style={{marginBottom: '10px'}}>{code}</p>
        <p style={{marginBottom: '10px'}}><i>Completed on {date}</i></p>
        {seeMoreElement}
      </div>
    );

  } else {

    bodyElement = <p><i>In progress<br /><br />Started on {date}</i></p>;
  }


  return (
    <div class='flex-column my-assessment-header'>
      {bodyElement}
    </div>
  );
}

export default AssessmentHeader;