import { useState, useRef } from "react";

import StartHeader from "./startHeader";
import ProgressDots from "../../utilities/progressDots";
import ButtonSubmitRound from "../../utilities/forms/buttonSubmitRound";

const audioUrl = "https://files.flowspeak.io/files/assessments/audio/speak-like-a-pro.mp3";


function AssessmentAudio(props) {

  // Define variables
  const [playButtonDisabled, setPlayButtonDisabled] = useState(false);
  const [opacity, setOpacity] = useState(1);
  const [status, setStatus] = useState(0);
  const audioPlayerRef = useRef(null);
  const logProgress = props.logProgress;
  const instructions = props.instructions;
  const organizationLogo = props.organizationLogo;


  // Define functions
  function playAudio() {
    setPlayButtonDisabled(true);
    setOpacity(.5);

    const audioPlayer = audioPlayerRef.current;
    if( audioPlayer ) {
      audioPlayer.volume = 1;
      audioPlayer.play().catch((e) => {
        // Catch error because this method won't work on mobile safari
      });
    }
  }

  function afterPlayEnds() {
    setStatus(1);
  }

  function handleYesPress() {
    setStatus(2);
  }

  function handleNoPress() {
    setStatus(3);
    setPlayButtonDisabled(false);
    setOpacity(1);
  }


  // Screen components
  let instruction;
  let instructionTranslation;
  let buttonsElement = <div class='flex-row assess-audio-buttons-container'></div>;
  let nextStepElement = (
    <ButtonSubmitRound
      value={"Continue"}
      disabled={true}
    />
  );

  if( status === 0 ) {
    instruction = instructions.check.body;
    instructionTranslation = instructions.check.translation;
  } else if( status === 1 ) {
    instruction = instructions.hear.body;
    instructionTranslation = instructions.hear.translation;

    buttonsElement = (
      <div style={{marginTop: '30px'}}>
        <p class='has-text-centered'><i>Did you hear the voice?</i></p>
        <div class='flex-row assess-audio-buttons-container'>
          <button class='button' onClick={() => handleYesPress()}>Yes</button>
          <button class='button' onClick={() => handleNoPress()}>No</button>
        </div>
      </div>
    );

  } else if( status === 2 ) {
    instruction = instructions.next.body;
    instructionTranslation = instructions.next.translation;

    nextStepElement = (
      <ButtonSubmitRound
        value={"Continue"}
        handleButtonClick={() => logProgress({step: 4})}
      />
    );

  } else if( status === 3 ) {
    instruction = instructions.error.body;
    instructionTranslation = instructions.error.translation;
  }


  return (
    <>
      <StartHeader defaultOrgLogo={organizationLogo} />
      <div class='flex-column assess-glass-extra'>

        <div class='flex-column' style={{width: '100%'}}>
          <h2>Let's check your audio</h2>

          <div class='flex-column assess-audio-button-container' style={{opacity: opacity}}>
            <button
              class='icon audio-icon icon-audio-outline-light assess-audio-button'
              title='Hear the text spoken out loud'
              onClick={() => playAudio()}
              disabled={playButtonDisabled}
            >
              <audio
                ref={audioPlayerRef}
                onEnded={() => afterPlayEnds()}
              >
                <source src={audioUrl} type="audio/mpeg" />
                Your browser does not support the audio element.
              </audio>
            </button>
          </div>

          <div class='flex-column' style={{marginTop: '15px'}}>
            <p class='has-text-centered'>{instruction}</p>
            <p class='translation-p has-text-centered'>{instructionTranslation}</p>
          </div>

          {buttonsElement}
        </div>

        <div class='flex-column'>
          {nextStepElement}

          <div class='assess-dots-container'>
            <ProgressDots index={2} dots={5} />
          </div>
        </div>
      </div>
    </>
  );
}

export default AssessmentAudio;