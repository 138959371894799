import { useState } from "react";

import { Mixpanel } from "../utilities/analytics/mixpanelHelpers";
import AssessmentHeader from "./assessmentHeader";
import AssessmentBody from "./assessmentBody";
import AssessmentModalBody from "./assessmentModalBody";
import Modal from "../utilities/modal";


function ViewAssessment(props) {

  // Define variables
  const [modalElement, setModalElement] = useState(null);
  const assessment = props.assessment;
  const id = assessment.id;
  const status = assessment.status;
  const complete = (status === 'Complete');


  // Define functions
  function handleClick() {
    if( !complete ) { return }

    const modal = (
      <Modal
        windowClass={'my-assessments-modal'}
        closeModal={closeModal}
        title={'Assessment Details'}
        centerTitle={true}
        body={<AssessmentModalBody id={id} assessment={assessment} />}
        closeIcon={true}
      />
    );

    setModalElement(modal);

    // Track the event
    Mixpanel.track(`Event: Veiw Assessment Details Modal`);
  }

  function closeModal() {
    setModalElement(null);
  }


  // Screen components
  let bodyElement;
  if( complete ) {
    bodyElement = <AssessmentBody assessment={assessment} />;
  }


  return (
    <>
      {modalElement}

      <button type='button' class='my-assessment-container' onClick={() => handleClick()} style={complete ? null : {cursor: 'default'}}>
        <AssessmentHeader assessment={assessment} showSeeMoreElement={true} />
        {bodyElement}
      </button>
    </>
  );
}

export default ViewAssessment;