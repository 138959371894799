import React from "react";
import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router-dom";

import '../../styles/aitalk.css';

import alertMessage from '../utilities/alertMessage';
import { reportError } from "../../functions/sentry";
import { Mixpanel } from "../utilities/analytics/mixpanelHelpers";
import apiCall from '../utilities/auth/apiCall';
import { getAiTalk, updateSavedAiTalks, logExerciseStart } from '../utilities/auth/authApi';
import sendExerciseData from '../../functions/sendExerciseData';

import AitalkHeader from "./aitalkHeader";
import ExpressionRelated from '../expression/screens/expressionRelated';
import ProgressSteps from '../utilities/progressSteps';
import LessonCompleteModal from "../utilities/lessonCompleteModal";
import AiTalkThread from "./aiTalkThread";

import lessonSlice from "../../store/lesson";
import aiTalkSlice from "../../store/aiTalk";
import savedLessonsSlice from "../../store/savedlessons";
import dashboardSlice from "../../store/dashboard";


function AiTalkScreen() {

  // Dispatch
  const dispatch = useDispatch();

  function setActivePlaying(status) {
    dispatch(lessonSlice.actions.setActivePlaying(status));
  }

  function setAiTalk(payload) {
    dispatch(aiTalkSlice.actions.setAiTalk(payload));
  }

  function addMessage(messageObject) {
    dispatch(aiTalkSlice.actions.addMessage(messageObject));
  }

  function addResponse(responseObject) {
    dispatch(aiTalkSlice.actions.addResponse(responseObject));
  }

  function setExerciseId(id) {
    dispatch(aiTalkSlice.actions.setExerciseId(id));
  }

  function setIsSaved(value) {
    dispatch(aiTalkSlice.actions.setAiTalkIsSaved(value));
  }

  function resetLesson() {
    dispatch(aiTalkSlice.actions.resetAiTalk());
  }

  function invalidateSavedLessons() {
    dispatch(savedLessonsSlice.actions.invalidateSavedLessons());
  }

  function clearDashboardData() {
    dispatch(dashboardSlice.actions.clearDashboardData());
  }


  // Define variables
  const { id } = useParams();
  const isInitialized = useSelector((state) => state.aiTalk.isInitialized);
  const aiTalk = useSelector((state) => state.aiTalk.lessonData);
  const messages = useSelector((state) => state.aiTalk.messages);
  const responses = useSelector((state) => state.aiTalk.responses);
  const isSaved = useSelector((state) => state.aiTalk.isSaved);
  const exerciseId = useSelector((state) => state.aiTalk.exerciseId);
  const avatarUrl = useSelector((state) => state.profile.avatarUrl);

  const learnerHasAccessToLesson = aiTalk.learnerHasAccessToLesson;
  const iconUrl = aiTalk.iconUrl;
  const imageUrl = aiTalk.imageUrl;
  const title = aiTalk.title;
  const initialDisplayMessage = aiTalk.initialDisplayMessage;
  const description = aiTalk.description;
  const everyMessageAddonResponderText = aiTalk.everyMessageAddonResponderText;
  const relatedLessons = aiTalk.relatedLessons;
  const learnerSpeakingTurns = aiTalk.learnerSpeakingTurns + 1;
  const firstExampleExtraContext = aiTalk.firstExampleExtraContext;
  const extras = aiTalk.extras;
  const stepCount = messages.filter(m => m.role === 'user').length;

  const [completeElement, setCompleteElement] = useState(null);


  // Component load actions
  useEffect(() => {

    setActivePlaying(false);

    let fetchData = false;
    if( aiTalk?.id !== Number(id) ) { fetchData = true }
    if( !isInitialized ) { fetchData = true }

    if( fetchData ) {
      apiCall(getAiTalk, id).then((response) => {

        if( response.success ) {
          const lessonInfo = response.data.aiTalk;

          const reduxInfo = {
            id: id,
            lessonData: lessonInfo,
            isSaved: lessonInfo.isSaved,
            isIelts: lessonInfo.isIelts,
            messages: lessonInfo.starterMessages
          };

          setAiTalk(reduxInfo);
        } else {
          alertMessage(response.error, 'error');
          reportError(response.error);
        }

      });
    }

    // scrollContainer();
    // sendMessages();

    // Log the lesson start if the learner makes their first response
    if( stepCount === 2 && !exerciseId ) {
      logStart();
    }

  // }, [id, messages]);
  }, [id]);


  // Define functions
  function saveLesson() {

    const payload = {
      id: id,
      is_saved: isSaved
    }

    apiCall(updateSavedAiTalks, payload).then((response) => {
      if( response.success ) {
        setIsSaved(response.data.isSaved);
        invalidateSavedLessons();
      } else {
        alertMessage(response.error, 'error');
      }
    });
  }

  function logStart() {

    const payload = {
      lesson_id: id,
      started: true,
      lesson_type: 'AiTalk'
    }

    apiCall(logExerciseStart, payload).then((response) => {
      if( response.success ) {
        setExerciseId(response.data.exerciseId);
      }
    });
  }

  function completeLesson() {

    // setDoneButtonLoading(true);

    // Send the responses to the server
    const payload = {
      lessonType: 'AiTalk',
      exerciseId: exerciseId,
      lessonId: id,
      responsesToSave: responses
    }

    sendExerciseData(payload).then((response) => {
      if( response.success ) {

        setCompleteElement(
          <LessonCompleteModal
            data={response.data}
            reset={reset}
          />
        );

        // Make the dashboard recall lesson data
        // invalidateInitialized();
        clearDashboardData();

        // Log the event
        Mixpanel.track('Event: Lesson Complete', {'Lesson ID': id, 'Lesson Type': 'AI Talk'});

      } else {
        alertMessage(response.error, 'error');
      }
    }).finally(() => {
      // setTimeout(() => setDoneButtonLoading('finalized'), 1200);
    });
  }

  function reset() {
    // Reset the lesson
    resetLesson();
    setExerciseId(null);
    setCompleteElement(null);
  }


  return (
    <div class='wrapper'>

      {completeElement}

      <div class='lesson-body aitalk'>

        <div class='lesson-left-column'>
          <p class='related-lessons-title'>Related Lessons</p>
          <ExpressionRelated
            relatedLessons={relatedLessons}
          />
        </div>

        <div class='lesson-right-column'>
          <section class='aitalk-header-wrapper'>
            <AitalkHeader
              title={title}
              saveLesson={saveLesson}
              iconUrl={iconUrl}
              imageUrl={imageUrl}
              description={description}
            />

            <div style={{padding: '15px 30px', marginBottom: '15px'}}>
              <ProgressSteps
                steps={learnerSpeakingTurns}
                currentStep={stepCount}
              />
            </div>
          </section>

          <AiTalkThread
            id={id}
            learnerHasAccessToLesson={learnerHasAccessToLesson}
            isInitialized={isInitialized}
            exerciseId={exerciseId}
            learnerSpeakingTurns={learnerSpeakingTurns}
            initialDisplayMessage={initialDisplayMessage}
            everyMessageAddonResponderText={everyMessageAddonResponderText}
            firstExampleExtraContext={firstExampleExtraContext}
            avatarUrl={avatarUrl}
            iconUrl={iconUrl}
            messages={messages}
            addMessage={addMessage}
            addResponse={addResponse}
            completeLesson={completeLesson}
            logStart={logStart}
            extras={extras}
          />

        </div>
      </div>
    </div>
  );
}

export default AiTalkScreen;