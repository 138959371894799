import { createStore, combineReducers } from 'redux';

import profileSlice from './profile';
import allSeriesSlice from "./allseries";
import aiTalkSlice from "./aiTalk";
import boosterSlice from "./booster";
import coursesSlice from "./courses";
import dashboardSlice from "./dashboard";
import expressionSlice from "./expression";
import lessonSlice from "./lesson";
import notificationsSlice from "./notifications";
import packageSlice from "./package";
import postsSlice from "./posts";
import progressLessonsSlice from "./progressLessons";
import savedLessonsSlice from "./savedlessons";
import myAssessmentsSlice from './myAssessments';


export const store = createStore(
  combineReducers({
    profile: profileSlice.reducer,
    allSeries: allSeriesSlice.reducer,
    aiTalk: aiTalkSlice.reducer,
    booster: boosterSlice.reducer,
    courses: coursesSlice.reducer,
    dashboardData: dashboardSlice.reducer,
    expression: expressionSlice.reducer,
    lesson: lessonSlice.reducer,
    notifications: notificationsSlice.reducer,
    package: packageSlice.reducer,
    posts: postsSlice.reducer,
    progressLessons: progressLessonsSlice.reducer,
    savedLessons: savedLessonsSlice.reducer,
    myAssessments: myAssessmentsSlice.reducer
  })
)