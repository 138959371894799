import { useState, useEffect } from "react";

import alertMessage from "../utilities/alertMessage";
import apiCall from "../utilities/auth/apiCall";
import { getAssessment } from "../utilities/auth/authApi";

import AssessmentHeader from "./assessmentHeader";
import AssessmentBody from "./assessmentBody";
import AssessmentsResponse from "./assessmentsResponse";
import LoadingBasic from "../utilities/loadingbasic";


function AssessmentModalBody(props) {

  // Define variables
  const [assessmentDetailData, setAssessmentDetailData] = useState({});
  const [loading, setLoading] = useState(true);
  const id = props.id;
  const assessment = props.assessment;
  const status = assessment.status;
  const complete = (status === 'Complete');


  // Component load actions
  useEffect(() => {
    apiCall(getAssessment, {id: id}).then((response) => {
      if( response.success ) {
        setAssessmentDetailData(response.data);
        setLoading(null);
      } else {
        alertMessage(response.error, 'error');
      }
    });
  }, []);


  // Screen components
  let bodyElement;
  if( loading ) {
    bodyElement = <LoadingBasic />;
  } else {

    let scoresElement;
    if( complete ) {
      scoresElement = <AssessmentBody assessment={assessment} />;
    }

    bodyElement = (
      <div class='my-assessment-container'>
        <AssessmentHeader assessment={assessment} />
        {scoresElement}
        <div class='my-assessment-responses-container'>
          {assessmentDetailData.responses.map((response, index) =>
            <AssessmentsResponse key={`my-assessment-response-${index}`} response={response} />
          )}
        </div>
      </div>
    );
  }


  return (
    <div>
      {bodyElement}
    </div>
  );
}

export default AssessmentModalBody;