import AssessmentSubScore from "./assessmentSubScore";


function AssessmentBody(props) {

  // Define variables
  const assessment = props.assessment;
  const scorePronunciation = assessment.scorePronunciation;
  const scoreGrammar = assessment.scoreGrammar;
  const scoreFluency = assessment.scoreFluency;
  const scoreVocabulary = assessment.scoreVocabulary;
  const scoreRelevance = assessment.scoreRelevance;


  return (
    <div class='flex-column my-assessment-body'>
      <div class='my-assessment-body-inner'>
        <AssessmentSubScore title={'Fluency'} score={scoreFluency} />
        <AssessmentSubScore title={'Relevance'} score={scoreRelevance} />
        <AssessmentSubScore title={'Pronunciation'} score={scorePronunciation} />
        <AssessmentSubScore title={'Grammar'} score={scoreGrammar} />
        <AssessmentSubScore title={'Vocabulary'} score={scoreVocabulary} />
      </div>
    </div>
  );
}

export default AssessmentBody;