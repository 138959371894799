import axios from 'axios';
import { reportError } from './sentry';
import { Buffer } from 'buffer';


function generateAudio(payload) {

  let apiUrlBase = "https://microservice.flowspeak.io/";
  const apiUrlString = "api/v2/generate_audio";




  // // -------------------------------------
  // // For testing and staging
  // // -------------------------------------
  // const host = window.location.host;
  // const onStaging = host.search('--flow-web.netlify.app') > 0;
  // if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development' || onStaging) {

  //   // apiUrlBase = "https://nvl-api-microservice-staging.herokuapp.com/";
  //   apiUrlBase = "http://localhost:8080/";
  // }
  // // -------------------------------------



  const apiUrl = `${apiUrlBase}${apiUrlString}`;

  const message = payload.message;
  const speakerId = payload.speakerId;

  const formData = new FormData();
  formData.append('apiKey', "fs000000000003044092320002333");
  formData.append('message', message);
  formData.append('speakerId', speakerId);

  const config = {
    method: 'post',
    url: apiUrl,
    headers: { "Content-Type": "multipart/form-data" },
    data : formData,
  };

  return axios(config).then((responseData) => {
    if( responseData.data) {
      const response = responseData.data;

      if( response.success ) {

        let messageAudioUrl;
        const audioBufferString = response.data.audioBuffer;
        if( audioBufferString ) {
          const audioBufferParsed = JSON.parse(audioBufferString);

          const audioBuffer = Buffer.from(audioBufferParsed, "base64");
          const blob = new Blob([audioBuffer], {type: 'audio/wav'});

          messageAudioUrl = window.URL.createObjectURL(blob);
        }

        return { success: true, messageAudioUrl: messageAudioUrl }
      } else {
        return { success: false, error: response.error }
      }
    } else {
      return { success: false, error: 'Sorry, there was an error, please try again.' }
    }

  }).catch(function (error) {
    reportError(error);
  });

}


export default generateAudio;