function AssessmentSubScore(props) {

  // Define variables
  const title = props.title;
  const score = props.score;


  return (
    <div class='flex-row my-assessment-sub-score'>
      <span>{title}</span>
      <span>
        {score} <span class='my-assess-small'>/ 100</span>
      </span>
    </div>
  );
}

export default AssessmentSubScore;