import '../../styles/priceComparison.css';

function PriceComparisonTable() {


  return (
    <div class='price-comparision-table-wrapper'>
      <h2>Why Get Premium?</h2>

      <div class='price-comparision-table-container'>

        <div class='feature-row head'>
          <p>Lessons</p>
          <div><p>Community</p></div>
          <div><p>Premium</p></div>
        </div>

        <div class='feature-row'>
          <p>Beginner</p>
          <div><span class='icon icon-complete-purple'></span></div>
          <div><span class='icon icon-complete-purple'></span></div>
        </div>

        <div class='feature-row'>
          <p>Intermediate & Advanced</p>
          <div><span class='icon icon-not-included'></span></div>
          <div><span class='icon icon-complete-purple'></span></div>
        </div>

        <div class='feature-row'>
          <p>Business expressions</p>
          <div><span class='icon icon-not-included'></span></div>
          <div><span class='icon icon-complete-purple'></span></div>
        </div>

        <div class='feature-row'>
          <p>1,200+ lesson library</p>
          <div><span class='icon icon-not-included'></span></div>
          <div><span class='icon icon-complete-purple'></span></div>
        </div>

        {/* ---- */}

        <div class='feature-row head'>
          <p>Courses</p>
          <div>&nbsp;</div>
          <div>&nbsp;</div>
        </div>

        <div class='feature-row'>
          <p>Beginner</p>
          <div><span class='icon icon-complete-purple'></span></div>
          <div><span class='icon icon-complete-purple'></span></div>
        </div>

        <div class='feature-row'>
          <p>Intermediate & Advanced</p>
          <div><span class='icon icon-not-included'></span></div>
          <div><span class='icon icon-complete-purple'></span></div>
        </div>

        <div class='feature-row'>
          <p>IELTS speaking prep</p>
          <div><span class='icon icon-not-included'></span></div>
          <div><span class='icon icon-complete-purple'></span></div>
        </div>

        {/* ---- */}

        <div class='feature-row head'>
          <p>Feedback</p>
          <div>&nbsp;</div>
          <div>&nbsp;</div>
        </div>

        <div class='feature-row'>
          <p>Community</p>
          <div><span class='icon icon-complete-purple'></span></div>
          <div><span class='icon icon-complete-purple'></span></div>
        </div>

        <div class='feature-row'>
          <p>Professional</p>
          <div><span class='icon icon-not-included'></span></div>
          <div><span class='icon icon-complete-purple'></span></div>
        </div>

        <div class='feature-row'>
          <p>Advanced speech scoring</p>
          <div><span class='icon icon-not-included'></span></div>
          <div><span class='icon icon-complete-purple'></span></div>
        </div>

        {/* ---- */}

        <div class='feature-row head'>
          <p>Assessments</p>
          <div>&nbsp;</div>
          <div>&nbsp;</div>
        </div>

        <div class='feature-row'>
          <p>1 per month</p>
          <div><span class='icon icon-not-included'></span></div>
          <div><span class='icon icon-complete-purple'></span></div>
        </div>

        {/* ---- */}

        <div class='feature-row'>
          <p>&nbsp;</p>
          <div>&nbsp;</div>
          <div>&nbsp;</div>
        </div>



        {/* <div class='features-description'>

          <p class='head'>Lessons</p>
          <p>Beginner</p>
          <p>Intermediate & Advanced</p>
          <p>Business expressions</p>
          <p>400+ lesson library</p>

          <p class='head'>Courses</p>
          <p>Beginner</p>
          <p>Intermediate & Advanced</p>
          <p>IELTS speaking prep</p>

          <p class='head'>Feedback</p>
          <p>Community</p>
          <p>Professional</p>
          <p>Advanced speech scoring</p>

        </div>

        <div class='features-community'>

          <p class='head'>Community</p>
          <span class='icon icon-complete-purple'></span>
          <span class='icon icon-not-included'></span>
          <span class='icon icon-not-included'></span>
          <span class='icon icon-not-included'></span>

          <p class='head'>&nbsp;</p>
          <span class='icon icon-complete-purple'></span>
          <span class='icon icon-not-included'></span>
          <span class='icon icon-not-included'></span>

          <p class='head'>&nbsp;</p>
          <span class='icon icon-complete-purple'></span>
          <span class='icon icon-not-included'></span>
          <span class='icon icon-not-included'></span>

        </div>

        <div class='features-premium'>

          <p class='head'>Premium</p>
          <span class='icon icon-complete-purple'></span>
          <span class='icon icon-complete-purple'></span>
          <span class='icon icon-complete-purple'></span>
          <span class='icon icon-complete-purple'></span>

          <p class='head'>&nbsp;</p>
          <span class='icon icon-complete-purple'></span>
          <span class='icon icon-complete-purple'></span>
          <span class='icon icon-complete-purple'></span>

          <p class='head'>&nbsp;</p>
          <span class='icon icon-complete-purple'></span>
          <span class='icon icon-complete-purple'></span>
          <span class='icon icon-complete-purple'></span>

        </div> */}

      </div>
    </div>
  );
}

export default PriceComparisonTable;