import { Link } from "react-router-dom";
import { Mixpanel } from '../utilities/analytics/mixpanelHelpers';


function UpgradePromo(props) {

  // Define variables
  const profileSetup = props.profileSetup;
  const extraClass = profileSetup ? 'with-profile-setup' : '';


  // Define functions
  function afterClick() {
    Mixpanel.track('Event: Buy - click upgrade button on dashboard');
  }


  return (
    <div class={`dashboard-upgrade-promo-container ${extraClass}`}>
      <Link
        to={`/packages?code=30for2025`}
        title='Upgrade to Premium'
        style={{textDecoration: 'underline'}}
        onClick={() => afterClick()}
      >
        {/* Enjoying FLOW Speak? <strong style={{textDecoration: 'underline'}}>Get 30% off Premium</strong>. */}
        Upgrade to <strong>Premium</strong> for $5.25/month.
      </Link>
    </div>
  );
}

export default UpgradePromo;