import React from "react";
import { useEffect, useState } from 'react';


function ProfileSelect(props) {

  // Define variables
  const initialValue = props.value;
  const [value, setValue] = useState(initialValue);
  const id = props.id;
  const name = props.name;
  const title = props.title;
  const disabled = props.disabled;
  const label = props.label;
  const options = props.options;
  const enableField = props.enableField;
  const saveData = props.saveData;
  const cancelEdit = props.cancelEdit;


  // Component load actions
  useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  function handleInputChange(event) {
    const value = event.target.value;
    setValue(value);

    // Auto save
    saveData(id);
  }


  // Define functions
  function handleCancelEdit(id){
    setValue(initialValue);
    cancelEdit(id);
  }


  return (
    <div class='profile-input-wrapper'>
      <label>{label}</label>
      <div class='profile-input'>

        <select
          id={id}
          name={name}
          type='select'
          title={title}
          value={value}
          onChange={handleInputChange}
          disabled={disabled}
        >
          <option value=''>Select</option>
          {options.map((language, index) =>
            <option key={index} value={language.value}>{language.name}</option>
          )};
        </select>

        <div class='profile-input-buttons'>
          <button type='button' class='clean-button edit-button' onClick={() => enableField(id)}>
            <span class='icon icon-edit'></span>
            <span style={{marginLeft: '5px'}}>edit</span>
          </button>
          <button type='button' class='button clean-button save-button is-hidden' onClick={() => saveData(id)}>save</button>
          <button type='button' class='button clean-button cancel-button is-hidden' onClick={() => handleCancelEdit(id)}>cancel</button>
        </div>
      </div>
    </div>
  );
}

export default ProfileSelect;