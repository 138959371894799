import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  initialized: false,
  assessments: [],
  availableThisMonth: 0,
  message: '',
  code: null
}

export const name = 'myAssessments';

const myAssessmentsSlice = createSlice({
  name,
  initialState,
  // The `reducers` field allows us define reducers and generate associated actions
  reducers: {
    initializeMyAssessments: (state, action) => {
      state.initialized = true;
      state.assessments = action.payload.assessments;
      state.availableThisMonth = action.payload.availableThisMonth;
      state.message = action.payload.message;
      state.code = action.payload.code;
    },

    clearMyAssessments: (state, action) => {
      state.initialized = false;
      state.assessments = initialState.assessments;
      state.availableThisMonth = initialState.availableThisMonth;
      state.message = initialState.message;
      state.code = initialState.code;
    },
  },
});

export const { actions, reducer } = myAssessmentsSlice;
export default myAssessmentsSlice;