import React from "react";
import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import { Mixpanel } from '../utilities/analytics/mixpanelHelpers';
import alertMessage from '../utilities/alertMessage';
import { getSavedPromoCode, applyPromoCode } from "../utilities/auth/authApi";
import formatPromoCodeDescription from "../../functions/formatPromoCodeDescription";
import Modal from "../utilities/modal";

import packageSlice from "../../store/package";


function PromoCode(props) {

  // Dispatch functions
  const dispatch = useDispatch();

  function setPromoCodeRedux(object) {
    // dispatch({ type: 'setPromoCode', payload: {promoCode: object}});
    dispatch(packageSlice.actions.setPromoCode({promoCode: object}));
  }

  function invalidateInitialized() {
    // dispatch({ type: 'invalidateInitialized', payload: {}});
  }


  // Define variables
  const providedPromoCode = props.providedPromoCode || '';
  const autoUpgradePath = props.autoUpgradePath || '/dashboard';
  const navigate = useNavigate();
  const [promoCodeInitialized, setPromoCodeInitialized] = useState(false);
  const [promoCode, setPromoCode] = useState(providedPromoCode);
  const [promoCodeDescription, setPromoCodeDescription] = useState('');
  const [buttonLoading, setButtonLoading] = useState(false);
  const [modal, setModal] = useState(null);


  // Page load actions
  useEffect(() => {
    // if( !promoCodeInitialized ) {
      if( promoCode ) {

        setTimeout(() => {
          handleApplyClick();
        }, 500);

      } else {

        getSavedPromoCode().then((response) => {
          if( response?.data ) {
            setPromoCode(response.data.code);
            setPromoCodeInitialized(true);
          }
        });

      }
    // }
  }, [promoCodeInitialized]);



  // Define functions
  function handleRemoveClick() {
    setPromoCodeRedux({});
    setPromoCodeDescription('');
    setPromoCode('');
  }

  function handleApplyClick() {

    if( !promoCode || promoCode.length < 4 ) {
      alertMessage('Enter a promo code first', 'error');
      return;
    }

    Mixpanel.track('Event: Buy - click Apply Promo Code button on membership', {code: promoCode});

    setButtonLoading(true);

    const payload = {
      promo_code: promoCode,
      source: 'web'
    };

    applyPromoCode(payload).then((response) => {

      if( response && response.data ) {
        if( response.data.success ) {

          const data = response.data;
          const responseCode = data.responseCode;

          switch(responseCode) {
            case 'associatedToOrgGroup':
              alertMessage(data.message);
              break;
            case 'autoAssignCourse':
              alertMessage(data.message);
              break;
            case 'autoUpgrade':
              handleAutoUpgrade(data.autoUpgradeInfo);
              break;
            case 'stripeCoupon':
              handleStripeCoupon(data.couponInfo);
              break;
            default:
              // No default, do nothing
          }

        } else {
          alertMessage(response.data.error, 'error');
        }
      } else {
        // Handle
      }
    }).catch((error) => {
      // reportError(error);
    }).finally(() => {
      setButtonLoading(false);
    });

  }

  function handleAutoUpgrade(autoUpgradeInfo) {

    const title = autoUpgradeInfo.auto_upgrade_message_title;
    const body = autoUpgradeInfo.auto_upgrade_message_body;

    const modalContent = <Modal
      windowClass={''}
      title={title}
      // subtitle={''}
      body={body}
      closeButton={true}
      closeModal={navigateToAutoUpgradePath}
      bodyAsHtml={true}
    />;

    setModal(modalContent);
  }

  function navigateToAutoUpgradePath() {

    // Invalidate the This Week is Initalized store so the
    // This week completed data will refresh and include the latest
    // Completed counts
    invalidateInitialized();

    navigate(autoUpgradePath);
  }

  function handleStripeCoupon(couponInfo) {
    setPromoCodeRedux(couponInfo);
    const description = formatPromoCodeDescription(couponInfo);
    setPromoCodeDescription(description);
    alertMessage('Your promo code was applied');
  }


  // Screen components
  let promoCodeDescriptionElement;
  if( promoCodeDescription ) {
    promoCodeDescriptionElement = (
      <div class='promo-code-discount-description'>
        {promoCodeDescription}
      </div>
    );
  }

  let applyButtonElement;
  if( promoCodeDescription ) {
    applyButtonElement = (
      <button type='button' class='remove-promo-code' onClick={handleRemoveClick}>remove</button>
    );
  } else {
    applyButtonElement = (
      <button
        type='button'
        class='apply-promo-code'
        onClick={handleApplyClick}
        disabled={buttonLoading}
        >Apply</button>
    );
  }


  return (
    <div class='package-promo-code-wrapper'>
      {modal}
      <div class='promo-input-container'>
        <input
          class='input input-promo-code'
          autoComplete='off'
          placeholder="Promo Code"
          onChange={(event) => setPromoCode(event.target.value)}
          value={promoCode}
        />
        {applyButtonElement}
      </div>
      {promoCodeDescriptionElement}
    </div>
  );
};

export default PromoCode;