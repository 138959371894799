// import React from "react";
// import { useState, useEffect } from 'react';
import { useState } from 'react';
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import alertMessage from "../utilities/alertMessage.js";
import PackageFeatureList from "./packagefeaturelist";
import PackagePriceButton from "./packagepricebutton";

import { getStripePackageIds } from "../utilities/stripe/stripeHelpers.js";
import PromoCode from "./promocode";
import PriceComparisonTable from "../utilities/priceComparisonTable";

import packageSlice from "../../store/package.js";


function ChoosePackage(props) {

  // Dispatch functions
  const dispatch = useDispatch();

  function setPackageId(packageId) {
    dispatch({ type: 'setPackageId', payload: {packageId: packageId}});
    dispatch(packageSlice.actions.setPackageId({packageId: packageId}));
  }


  // Define functions
  const navigate = useNavigate();
  const pricingTier = useSelector((state) => state.profile.pricingTier);
  const stripePackageIds = getStripePackageIds(pricingTier);
  const [selectedPackage, setSelectedPackage] = useState(null);
  const packageFeaturesPlacement = props.packageFeaturesPlacement || 'top';

  let packageFeatures = (
    <div class='packages-details'>
      <p>
        <strong>All plans include:</strong>
      </p>
      <PackageFeatureList />
    </div>
  );

  const packageFeaturesTop = ( packageFeaturesPlacement === 'top' ) ? packageFeatures : null;

  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const codeFromUrl = urlParams.get('code');
  const code = codeFromUrl ? codeFromUrl : null;


  // // Page load actions
  // useEffect(() => {

  //   const queryString = window.location.search;
  //   const urlParams = new URLSearchParams(queryString);
  //   const code = urlParams.get('code');

  //   if( code ) {
  //     // TO DO - apply the code
  //   }

  // }, []);


  // Define functions
  function handleNextClick(event) {
    event.preventDefault();

    // Ensure a package is selected
    if( selectedPackage ) {

      const path = '/packages/review';

      // push package selection into redux
      setPackageId(selectedPackage);

      // Redirect to /purcase
      navigate(path);

    } else {
      alertMessage('Please select a subscription', 'error');
    }

  }


  return (
    <div class='choose-package-wrapper'>

      {packageFeaturesTop}

      <div class='choose-package-button-wrapper'>

        <PackagePriceButton
          packageId={stripePackageIds.monthlyPackageId.packageId}
          title={'Monthly Subscription'}
          duration={'month'}
          price={stripePackageIds.monthlyPackageId.price}
          commonName={'monthly'}
          selectedPackage={selectedPackage}
          setSelectedPackage={setSelectedPackage}
        />

        <PackagePriceButton
          packageId={stripePackageIds.sixMonthPackageId.packageId}
          title={'6-Month Subscription'}
          duration={'6-months'}
          price={stripePackageIds.sixMonthPackageId.price}
          discount={stripePackageIds.sixMonthPackageId.discount}
          commonName={'sixmonth'}
          selectedPackage={selectedPackage}
          setSelectedPackage={setSelectedPackage}
        />

        <PackagePriceButton
          packageId={stripePackageIds.yearlyPackageId.packageId}
          title={'Yearly Subscription'}
          duration={'year'}
          price={stripePackageIds.yearlyPackageId.price}
          discount={stripePackageIds.yearlyPackageId.discount}
          commonName={'yearly'}
          selectedPackage={selectedPackage}
          setSelectedPackage={setSelectedPackage}
        />

      </div>

      <PromoCode providedPromoCode={code} />

      <div class='action-button-wrapper'>
        <button class='button action-button' onClick={handleNextClick}>Review</button>
        <div class='sub-line'>You won't be billed yet</div>
      </div>

      <br />
      <PriceComparisonTable />

    </div>
  );
};

export default ChoosePackage;