import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from "react-redux";
import { Link } from 'react-router-dom';

import { Mixpanel } from '../utilities/analytics/mixpanelHelpers';
import alertMessage from '../utilities/alertMessage';
import apiCall from '../utilities/auth/apiCall';
import { getMyAssessments } from "../utilities/auth/authApi";
import Nav from "../utilities/nav";
import HeaderMain from "../utilities/headermain";
import BottomNav from "../utilities/bottomnav";
import LoadingBasic from "../utilities/loadingbasic";
import ViewAssessment from './viewAssessment';

import myAssessmentsSlice from "../../store/myAssessments";
import dashboardSlice from '../../store/dashboard';


function MyAssessmentsScreen() {

  // Define Dispatch functions
  const dispatch = useDispatch();

  function initalizeData(data) {
    dispatch(myAssessmentsSlice.actions.initializeMyAssessments(data));
  }

  function clearMyAssessments() {
    dispatch(myAssessmentsSlice.actions.clearMyAssessments());
  }

  function clearDashboardData() {
    dispatch(dashboardSlice.actions.clearDashboardData());
  }


  // Define variables
  const assessementsInApp = useSelector((state) => state.profile.assessementsInApp);
  const assessmentData = useSelector((state) => state.myAssessments);
  const assessmentsLoaded = assessmentData.initialized;
  const assessments = assessmentData.assessments;
  const availableThisMonth = assessmentData.availableThisMonth;
  const message = assessmentData.message;
  const code = assessmentData.code;

  const [loading, setLoading] = useState((assessmentsLoaded ? null : true));

  const available = (availableThisMonth === 1);


  // Component load actions
  useEffect(() => {
    if( !assessmentsLoaded ) {
      apiCall(getMyAssessments).then((response) => {
        if( response.success ) {
          initalizeData(response.data);
          setLoading(null);
        } else {
          alertMessage(response.error, 'error');
        }
      });
    }
  }, [assessmentsLoaded]);


  // Define functions
  function handleStartClick() {

    // Clear the dashboard data so the completed assessment will be acknowledged
    // there may be a lag where the person tookt he assessment, came back to the dashbard
    // and then clicked it again - that's when the update will show
    setTimeout(() => {
      clearDashboardData();
      clearMyAssessments();
    }, 2000);

    // Track the event
    Mixpanel.track(`Event: Self Start Assessment`, {location: 'My Assessments'});
  }


  // Screen components
  let assessmentText;
  let takeAssessmentButtonElement;

  if( available ) {
    takeAssessmentButtonElement = <a href={`/assessments/start/${code}`} class='my-assessment-start-button' target='_blank' onClick={() => handleStartClick()}>Start</a>;
  } else {
    if( message === 'premium' ) {
      assessmentText = (
        <div class='my-assessment-upgrade-message'>
          <p>Assessments are a Premium feature</p>
          <Link to='/packages' title='View Plans'>
            <p>Upgrade so you can take an Assessment each month</p>
          </Link>
        </div>
      );
    } else {
      assessmentText = <p class='my-assessment-gray' style={{marginTop: '15px'}}><i>{message}</i></p>;
    }
  }

  let bodyElement;
  if( loading ) {
    bodyElement = <LoadingBasic />;
  } else {
    bodyElement = (
      <div class='flex-row my-assessments-container'>

        <div class='my-assessment-container'>
          <div class='flex-column my-assessment-header'>
            <h3>Take an Assessment</h3>

            <div class='my-assessment-text-description'>
              <div style={{marginTop: '15px', marginBottom: '30px'}}>
                <p>Track your progress. It takes 5 - 10 minutes and you will receive an <span class='nowrap'>instant score</span>.</p>
              </div>
              {assessmentText}
            </div>

            {takeAssessmentButtonElement}
            </div>
        </div>

        {assessments.map((assessment, index) =>
          <ViewAssessment key={`view-assessment-${index}`} assessment={assessment} />
        )}

      </div>
    );
  }

  // Look for false b/c undefined just means we don't have the data yet
  if( assessementsInApp === false ) {
    bodyElement = (
      <div>
       <p>Sorry, your organization does not have access to Assessments from this screen.</p>
       <p style={{marginTop: '15px'}}>Please contact your organization administrator for assistance.</p>
      </div>
    );
  }


  return (
    <div class='wrapper gray-background'>
      <Nav />

      <div class='main-body'>
        <HeaderMain title="Assessments" />
        <div class='main-body-content assessments'>
          {bodyElement}
        </div>
      </div>
      <BottomNav />
    </div>
  );
}

export default MyAssessmentsScreen;