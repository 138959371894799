function AssessResponseScore(props) {

  // Define variables
  const title = props.title;
  const score = props.score;


  // Screen components
  let scoreElement;
  let feedbackElement;

  if( score.label ) {
    scoreElement = (
      <p class='assess-score-heading'>
        <span style={{display: 'inline-block', width: '130px'}}>{title}</span>
        <span class='assess-score-sub-score'>{score.score}<small>/100</small></span>
      </p>
    );
  }

  if( score.feedback ) {
    feedbackElement = <p class='my-assessment-gray'><i>{score.feedback}</i></p>;
  }

  return (
    <div>
      {scoreElement}
      {feedbackElement}
    </div>
  )
}

export default AssessResponseScore;