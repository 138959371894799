import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Link } from "react-router-dom";

import LessonCardPrimary from "../utilities/lessoncards/lessonCardPrimary";
import WeekListUpgradeCta from "./weekListUpgradeCta";
import ScoreDonutWithText from "../utilities/learning_module/scoreDonutWithText";
import BonusLessons from "./bonusLessons";
import AssessDashboardCard from './assessDashboardCard';


function WeekList(props) {

  // Define variables
  const assessmentData = useSelector((state) => state.dashboardData.assessmentData);
  const weekIndex = props.weekIndex;
  const thisWeekIndex = props.thisWeekIndex;
  const week = props.week;
  const lessons = week.lessons;
  const current = week.current;
  const title = current ? 'This Week' : week.title;
  const initalCollapse = !current;
  const score = week.score;
  const percentComplete = week.percentComplete;
  const isThisWeek = current;
  const completedLessonCount = week.completedLessonCount;
  const totalLessonCount = week.totalLessonCount;


  const [collapse, setCollapse] = useState(initalCollapse);

  const isIelts = week.isIelts;
  const scoreIelts = week.scoreIelts;

  const lessonCompleteText = `${completedLessonCount}/${totalLessonCount}`;

  const enabled = props.enabled || false;


  let scoreToUse = score;
  let scoreText = score ? `${score}%` : '--';

  if( isIelts ) {
    scoreToUse = scoreIelts || 0;
    scoreText = scoreIelts || '--';
  }

  const accessableLessons = lessons.filter(l => l.learnerHasAccessToLesson );
  const lockedLessons = lessons.filter(l => !l.learnerHasAccessToLesson );
  const bonusLessons = week.bonusLessons;


  // Component load actions
  useEffect(() => {
    if( !collapse ) {
      expandWeek();
    }
  }, []);


  // Define functions
  function handleWeekClick() {
    if( isThisWeek ) { return }
    if( collapse ) {
      expandWeek();
    } else {
      collapseWeek();
    }
  }

  function expandWeek() {
    setCollapse(false);
  }

  function collapseWeek() {
    setCollapse(true);
  }


  // Screen components
  let titleElement = <h1>{title}</h1>;
  if( !isThisWeek ) {
    titleElement = <h2>{title}</h2>;
  }

  let upgradeCtaElement;
  if( lockedLessons.length > 0 ) {
    upgradeCtaElement = <WeekListUpgradeCta />;
  }

  let weekListContainerClass = 'week-list-container';
  if( collapse ) {
    weekListContainerClass = 'week-list-container collapse';
  }
  if( isThisWeek ) { weekListContainerClass += ' is-this-week' }


  let bonusLessonsElement;
  if( weekIndex <= thisWeekIndex) {
    bonusLessonsElement = (
      <BonusLessons
        bonusLessons={bonusLessons}
        weekIndex={weekIndex}
        isThisWeek={isThisWeek}
      />
    );
  }

  let weekListBodyInnerClass = 'week-list-body-inner';
  let disabledElement;
  if( !enabled ) {

    weekListBodyInnerClass = 'week-list-body-inner disabled';

    disabledElement = (
      <div style={{width: '100%', textAlign: 'left', marginTop: '15px', marginBottom: '15px'}}>
        <p>Sorry, these lessons are not availability yet. Lessons unlock each week so come back often.</p>
        <p style={{marginBottom: '15px'}}>Try repeating your previous lessons to improve your memory and scores.</p>
        <Link to='/explore' title='View all lessons'>
        Still interested in more lessons? Explore the library.
        </Link>
      </div>
    );
  }

  let lessonsCompletedTitleElement;
  let weekScoreElement;

  if( current ) {
    lessonsCompletedTitleElement = <p class='helper-text'>Lessons Completed</p>;
    weekScoreElement = <p class='helper-text'>Week Score</p>;
  }

  // Show Assessment CTA
  let assessmentCardElement;
  if( current && assessmentData ) {
    assessmentCardElement = <AssessDashboardCard assessmentData={assessmentData} />;
  }


  return (
    <div class={weekListContainerClass}>

      <div class='flex-row week-list-header-container' id={isThisWeek ? 'this-week' : null}>

        <button
          class='clean-button week-list-title-button'
          onClick={() => handleWeekClick()}
          disabled={isThisWeek}
          title={isThisWeek ? null : 'Tap to expand / collapse'}
        >
          {titleElement}
        </button>

        <div class='flex-row week-score-container'>
          <div class='flex-column' title='Lessons completed this week'>
            <ScoreDonutWithText percent={percentComplete} text={lessonCompleteText} />
            {lessonsCompletedTitleElement}
          </div>
          <div class='flex-column' style={{marginLeft: '15px'}} title='Weekly Score'>
            <ScoreDonutWithText percent={scoreToUse} text={scoreText} isIelts={isIelts} />
            {weekScoreElement}
          </div>
        </div>

      </div>


      <div class='week-list-body'>

        {disabledElement}

        <div class={weekListBodyInnerClass}>

          {accessableLessons.map((lesson, index) => (
            <LessonCardPrimary
              lesson={lesson}
              key={`accessable-lesson-${index}`}
              index={index}
              isIelts={isIelts}
            />
          ))}

          {upgradeCtaElement}

          {lockedLessons.map((lesson, index) => (
            <LessonCardPrimary
              lesson={lesson}
              key={`locked-lesson-${index}`}
              index={index}
              isIelts={isIelts}
            />
          ))}

          {assessmentCardElement}

          {bonusLessonsElement}

        </div>

      </div>

    </div>
  );
}

export default WeekList;