import { useState, useEffect, useRef } from "react";

import PlayAudioIcon from "../playaudioicon";
import generateAudio from "../../../functions/generateAudio";


function AvatarWithAudio(props) {

  // Define variables
  const buttonRef = useRef(null);
  const [audioUrlGenerated, setAudioUrlGenerated] = useState(null);
  const [generatingAudio, setGeneratingAudio] = useState(false);
  const type = props.type;
  const avatarUrl = props.avatarUrl;
  const audioUrl = props.audioUrl;
  const size = props.size || 'small';
  const isAssistant = (type === 'assistant');
  const isLearner = (type === 'learner');

  const fullActive = isAssistant ? true : false;
  const fullDark = isLearner ? true : false;
  const extraClass = isLearner ? 'learner' : false;
  const position = isLearner ? 'left' : null;

  const hasPremium = props.hasPremium;
  const message = props.message;
  const speakerId = props.speakerId;
  let divStyle;


  // Component load actions
  useEffect(() => {

    // If we can, go ahead and get the audio
    if( isAssistant && hasPremium && message && !message.type ) {
      getAudio();
    }

  }, []);


  // Define functions
  function getAudio(autoPlay = false) {

    // Prevent double click
    if(generatingAudio ) { return }

    setGeneratingAudio(true);

    const payload = {
      message: message,
      speakerId: speakerId
    };

    generateAudio(payload).then((response) => {
      if( response?.success ) {
        setAudioUrlGenerated(response.messageAudioUrl);

        if( autoPlay && buttonRef.current ) {
          setTimeout(() => {buttonRef.current.click()}, 50);
        }
      }
    }).finally(() => {
      setGeneratingAudio(false);
    });
  }


  // Screen components
  let audioElement;
  if( audioUrl ) {
    audioElement = <PlayAudioIcon audioUrl={audioUrl} fullActive={fullActive} fullDark={fullDark} position={position} trackClick={'AI Talk or Booster - Learner'} hasPremium={hasPremium} />;
  } else {

    // Nest this in case audio is passed in for an assistant
    if( isAssistant ) {
      divStyle = {width: '24px', height: '24px'};

      if( audioUrlGenerated ) {
        audioElement = <PlayAudioIcon audioUrl={audioUrlGenerated} fullActive={fullActive} fullDark={fullDark} position={position} refRef={buttonRef} trackClick={'AI Talk or Booster - Assistant'} hasPremium={hasPremium} />;
      } else {
        const buttonClass = generatingAudio ? 'button is-loading icon-assistant-generate-audio' : 'icon audio-icon icon-audio-full-active';
        audioElement = <button class={buttonClass} onClick={() => getAudio(true)} />;
      }
    }

  }


  return (
    <div class={`avatar-with-audio-image-container ${size}`}>
      <img src={avatarUrl} width='45' alt='Manager icon' />
      <div class={`avatar-with-audio-audio-container ${extraClass}`} style={divStyle}>
        {audioElement}
      </div>
    </div>
  );
}

export default AvatarWithAudio;