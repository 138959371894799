import { useState, useEffect } from "react";

import LoadingBasic from "../../utilities/loadingbasic";
import AssessmentRegister from "./assessmentRegister";
import AssessmentInstructions from "./assessmentInstructions";
import AssessmentAudio from "./assessmentAudio";
import AssessmentMic from "./assessmentMic";
import AssessmentReady from "./assessmentReady";


function AssessmentStart(props) {

  // Define variables
  const [step, setStep] = useState(0);
  const config = props.config;
  const handleSteps = props.handleSteps;
  const setInstructions = props.setInstructions;
  const instructions = props.instructions;
  const defaultStep = props.defaultStep || 1;


  // Component load actions
  useEffect(() => {
    if( config.enableRegister ) {
      setStep(defaultStep);
    } else {
      setStep(2);
    }
  }, []);


  // Define functions
  function logProgress(data) {
    if( data.step ) {
      setStep(data.step);
    }

    handleSteps(data);
  }


  // Screen components
  let bodyContent;

  if( step === 0 ) {
    bodyContent = <LoadingBasic />;
  } else if( step === 1 ) {
    bodyContent = <AssessmentRegister config={config} logProgress={logProgress} setInstructions={setInstructions} />;
  } else if( step === 2 ) {
    bodyContent = <AssessmentInstructions instructions={instructions.assessment} logProgress={logProgress} organizationLogo={config.organizationLogo} />;
  } else if( step === 3 ) {
    bodyContent = <AssessmentAudio instructions={instructions.audio} logProgress={logProgress} organizationLogo={config.organizationLogo} />;
  } else if( step === 4 ) {
    bodyContent = <AssessmentMic instructions={instructions.mic} logProgress={logProgress} organizationLogo={config.organizationLogo} />;
  } else if( step === 5 ) {
    bodyContent = <AssessmentReady instructions={instructions.ready} logProgress={logProgress} organizationLogo={config.organizationLogo} />;
  }


  return (
    <>
      {bodyContent}
    </>
  );
}

export default AssessmentStart;