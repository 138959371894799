import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";

import { Mixpanel } from "../utilities/analytics/mixpanelHelpers";
import VectorIcon from "../utilities/vectorIcon";

import dashboardSlice from "../../store/dashboard";


function AssessDashboardCard(props) {

  // Dispatch
  const dispatch = useDispatch();

  function clearDashboardData() {
    dispatch(dashboardSlice.actions.clearDashboardData());
  }


  // Define variables
  const assessmentData = props.assessmentData;
  const available = assessmentData.available;
  const code = assessmentData.code;
  // const message = assessmentData.message;
  const completedAssessment = assessmentData.completedAssessment;


  // Define functions
  function handleStartClick() {

    // Clear the dashboard data so the completed assessment will be acknowledged
    // there may be a lag where the person tookt he assessment, came back to the dashbard
    // and then clicked it again - that's when the update will show
    setTimeout(() => {
      clearDashboardData();
    }, 2000);

    // Track the event
    Mixpanel.track(`Event: Self Start Assessment`, {location: 'Dashboard'});
  }


  // Screen components
  let bodyElement;
  if( completedAssessment ) {
    bodyElement = (
      <Link to={'/my-assessments'} class='lesson-card-content-container'>
        <div class='lesson-card-content'>
          <span class='flex-row'>
            <h6>Assessment Completed</h6>
            <VectorIcon icon='checkMarkCircle' color='#476CEA' size={'1.5rem'} />
          </span>
          <p style={{textDecoration: 'underline'}}>View your Assessment</p>
        </div>
      </Link>
    );
  } else {
    if( available === 1 ) {
      bodyElement = (
        <a
          class='flex-column lesson-card-content-container'
          href={`/assessments/start/${code}`}
          target='_blank'
          onClick={() => handleStartClick()}
        >
          <div class='lesson-card-content'>
            <h6>Take an Assessment</h6>
            <p>Track your progress and receive an instant score</p>
            <div class='flex-row dashboard-assessment-start-link'>
              Start
              &nbsp;
              <VectorIcon icon='chevronForwardFull' color='#ffffff' size={'1.5rem'} />
            </div>
          </div>
        </a>
      );
    } else {
      bodyElement = (
        <Link to={'/packages'} class='lesson-card-content-container'>
          <div class='lesson-card-content'>
            <span class='flex-row'>
              <h6>Take an Assessment</h6>
              <VectorIcon icon='lock' color='#8F8F8F' size={'1.15rem'} />
            </span>
            <p>Track your progress and receive an instant score</p>
            <p style={{marginTop: '15px', textDecoration: 'underline'}}>Upgrade to Premium to take Assessments</p>
          </div>
        </Link>
      );
    }
  }


  return (
    <div class='lesson-card-wrapper'>

      <div class='lesson-card-details-container dashboard-assessment-cta'>

        <div
          class='lesson-card-image'
          style={{backgroundImage: `url(https://files.flowspeak.io/images/assessment-background.jpg)`}}
        ></div>

        {bodyElement}

      </div>

    </div>
  );
}

export default AssessDashboardCard;