import ScoreSubLevelBlock from "./scoreSubLevelBlock";

const levelArray = ['A1.1', 'A1.2', 'A1.3', 'A2.1', 'A2.2', 'B1.1', 'B1.2', 'B2.1', 'B2.2', 'C1', 'C2'];
const inverseLevelArray = [...levelArray].reverse();



function ScoreSubLevel(props) {

  // Define variables
  const overall = props.overall;

  let indexLabel = overall.label;
  if( indexLabel.includes('C') ) {
    // Adjust to only be C1 or C2
    indexLabel = indexLabel.replace('.1', '').replace('.2', '')
  }

  const searchIndex = (element) => element === indexLabel;
  const foundIndex = inverseLevelArray.findIndex(searchIndex);
  const foundWidthExtra = foundIndex * 9;
  const foundWidth = `calc(9% + ${foundWidthExtra}%)`;


  return (
    <div class='flex-column assess-score-section'>
      <p class='assess-fancy'>Sub-level</p>

      <div class='assess-sub-container'>
        {levelArray.map((level, index) =>
          <ScoreSubLevelBlock
            key={`assess-sub-level-${index}`}
            index={index}
            inverseIndex={getInversePosition(levelArray, index)}
            level={level}
          />
        )}
      </div>

      <div class='assess-sub-indicator-container'>
        <div class='flex-column assess-sub-level' style={{width: foundWidth}}>
          <div class='flex-column assess-sub-level-inner'>
            <div class='assess-up-arrow'></div>
            <p>{overall.label}</p>
          </div>
        </div>
      </div>

      <a
        href='https://www.flowspeak.io/articles/pathways-level-up-your-speaking'
        rel="noreferrer"
        target='_blank'
        style={{marginTop: '30px', marginBottom: '-15px', color: '#6D6D6D', textDecoration: 'underline'}}
      >Learn more about CEFR scores</a>

    </div>
  );
}

export default ScoreSubLevel;



function getInversePosition(array, index) {
  return array.length - 1 - index;
}