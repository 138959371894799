import React from "react";


function PackageFeatureList () {

  return (
    <ul>
      <li><u>Unlimited access</u> to all Premium lessons</li>
      <li>Access to Premium courses</li>
      <li>Access to IELTS speaking prep courses</li>
      <li>Advanced speech scoring</li>
      <li>Professional feedback</li>
      <li>Save your favorite lessons</li>
      <li>1 Speaking Assessment per month</li>
      <li><strong>14 day money back guarantee</strong></li>
    </ul>
  );
};

export default PackageFeatureList;