import { Mixpanel } from '../utilities/analytics/mixpanelHelpers';


function PackagePriceButton (props) {

  // Define variables
  const packageId = props.packageId;
  const title = props.title;
  const price = props.price;
  const discount = props.discount;
  const duration = props.duration;
  const selectedPackage = props.selectedPackage
  const setSelectedPackage= props.setSelectedPackage


  let discountDiv;
  if( discount ) {
    discountDiv = <div class='package-discount'>{discount}</div>;
  }

  let buttonClass = 'choose-package-button';
  if( selectedPackage === packageId ) {
    buttonClass = 'choose-package-button selected';
  }


  // Define functions
  function handleClick() {
    setSelectedPackage(packageId);
    Mixpanel.track('Event: Buy - click package button on membership', {package: title});
  }


  return (
    <button type='button' class={buttonClass} onClick={() => handleClick()}>
      <p class='package-title'>{title}</p>
      <p class='package-price'>{price} / {duration}</p>
      {discountDiv}
    </button>
  );
}

export default PackagePriceButton;