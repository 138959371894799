import { useState, useEffect, useRef } from "react";

import { reportError } from "../../../functions/sentry";
import sendAssessmentResponses from "../../../functions/sendAssessmentResponses";
import sendAIChatMessage from "../../../functions/sendAIChatMessage";
import LoadingBasic from "../../utilities/loadingbasic";
import ProgressSteps from "../../utilities/progressSteps";
import Review from "../helpers/review";
import Message from "../../aitalk/message";
import AssessmentRecord from "../helpers/assessmentRecord";
import Modal from "../../utilities/modal";
import ButtonSubmitRound from "../../utilities/forms/buttonSubmitRound";
import ReviewTimer from "../helpers/reviewTimer";
import alertMessage from "../../utilities/alertMessage";


function ConversationThread(props) {

  // Define variables
  const scrollerRef = useRef(null);
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const [modal, setModal] = useState(null);
  const [status, setStatus] = useState('loading'); // loading, waiting, record, review, complete
  const [stepCount, setStepCount] = useState(0);
  const [messages, setMessages] = useState(props.messages);
  const [retryCount, setRetryCount] = useState(0);
  const [responseData, setResponseData] = useState({});
  const [responses, setResponses] = useState([]);
  const logProgress = props.logProgress;
  const instructionReview = props.instructionReview;
  const instructionComplete = props.instructionComplete;
  const speakerIconUrl = props.speakerIconUrl;
  const everyMessageAddon = props.everyMessageAddon;
  const secondaryMessageAddon = props.secondaryMessageAddon;
  const config = props.config;
  const timerSpeak = config.conversationSpeakTimer;
  const timerReview = config.conversationReviewTimer;
  const speakingTurns = config.conversationSpeakingTurns;
  const retryPossible = config.retryCount;
  const [cefrLevel, setCefrLevel] = useState(config.conversationStartLevel)


  // Component load actions
  useEffect(() => {

    // Get the first message
    sendMessages();

  }, [messages]);


  // Define functions
  function scrollContainer() {
    setTimeout(() => {
      if( scrollerRef && scrollerRef.current ) {
      scrollerRef.current.scrollTo({
        top: scrollerRef.current.scrollHeight,
        behavior: 'smooth'
      });
      }
    }, 500);
  }

  function addMessage(messageObject) {
    const updatedMessages = [...messages, messageObject];
    setMessages(updatedMessages);
  }

  function sendMessages() {

    if( status === 'complete' ) { return }

    const lastMessageRole = messages?.length ? messages[messages.length - 1].role : null;


    if( lastMessageRole === 'user' ) {

      const messagesToSend = JSON.parse(JSON.stringify(messages));

      // Clean the attributes b/c it makes the request fail
      messagesToSend.map((message) => {delete message.audioUrl });

      // Append some wording to message to give additional instructions
      const lastMessage = messagesToSend[messagesToSend.length - 1];
      lastMessage.content = lastMessageContent(lastMessage.content);


      const payload = {
        messages: messagesToSend,
        iconUrl: speakerIconUrl,
        extras: {cefrLevel: cefrLevel, toneLearner: 'professional'}
      }


      sendAIChatMessage(payload).then((response) => {
        if( response.success ) {

          const content = response.content;
          const message = content.message;

          // Look for the audio url
          const messageAudioUrl = response.messageAudioUrl;

          const newMessage = {role: "assistant", content: message, audioUrl: messageAudioUrl, iconUrl: speakerIconUrl};

          addMessage(newMessage);
          setStepCount(stepCount + 1);
          logProgress({conversationStep: stepCount + 1});
          setStatus('record');

          setTimeout(() => {
            scrollContainer();
          }, 200);

        } else {
          reportError(response.error);
          alertMessage(response.error, 'error');
        }
      });

    } else if( lastMessageRole === 'assistant') {
      // Do nothing
    }

  }

  function handleTranscript(responseObject) {
    if( responseObject.success ) {

      // Add the retry count
      responseObject.retryCount = retryCount;

      // Add the conversation message
      responseObject.aiMessage = messages[messages.length - 1].content;

      // Ensure the learner did not repeat the question
      const repeatedText = checkForRepeat(responseObject);

      if( repeatedText ) {
        cleanRetry();
        return;
      }


      setResponseData(responseObject);

      // setStatus('review'); // Disable review
      handleNextAction(responseObject);

    } else {
      if( responseObject.error === 1001 ) {

        // Handle empty object
        const emptyObject = {
          success: true,
          learnerDidNotRecord: true,
          scoreTwo: {transcript: " "}
        }

        setResponseData(emptyObject);
        setStatus('review');

      } else {
        reportError(`Conversation Thread error: ${JSON.stringify(responseObject)}`);
        alertMessage('Sorry, there was an error. Please contact support@flowspeak.io for assistance.', 'error');
      }
    }
  }

  function cleanRetry() {
    setStatus(' ');
    setResponseData({});

    setTimeout(() => {
      setStatus('record');
    }, 1000);

    alertMessage('Tip - answer the question directly without repeating it.', 'error');
  }

  function retry() {
    setStatus('record');
    setResponseData({});
    setRetryCount(1);
  }

  function handleNextAction(responseData) {
    if( responseData.success ) {

      // Save the repsonse data
      const updatedResponses = [...responses, responseData];
      setResponses(updatedResponses);

      // Update the CEFR level
      const newCefrLevel = getNewCefrLevel(responseData);
      setCefrLevel(newCefrLevel);

      // Add the message
      const newMessage = {
        role: "user",
        content: responseData.scoreTwo.transcript,
        audioUrl: responseData.audioUrl,
        avatarUrl: 'https://files.flowspeak.io/files/default-avatar.png'
      }

      addMessage(newMessage);


      if( speakingTurns === stepCount ) {
        setStatus('complete');

        scrollContainer();

        // // Handle the last step
        // setTimeout(() => {
        //   saveData(updatedResponses);
        // }, 1500);

      } else {
        setStatus('waiting');
      }
    } else {
      alertMessage(responseData.error, 'error');
    }
  }

  function saveData(updatedResponses) {
    const modalBody = (
      <div class='flex-column'>
        <br />
        <br />
        <LoadingBasic />
      </div>
    );

    setModal(
      <Modal
        // windowClass={'aitalk-message-modal'}
        title={'Saving your data'}
        centerTitle={true}
        body={modalBody}
      />
    );

    const payload = {
      type: 'conversation',
      code: config.code,
      responses: updatedResponses
    }

    sendAssessmentResponses(payload).then((response) => {
      if( response.success ) {
        setTimeout(() => {
          logProgress({section: 4, step: 0});
        }, 1000);
      } else {
        reportError(response.error);

        setModal(null);
        alertMessage(response.error, 'error');
      }
    });
  }

  function lastMessageContent(content) {

    if( stepCount < 2 && everyMessageAddon ) {
      content = `${content} ${everyMessageAddon}`;
    }

    if( stepCount >= 2 && secondaryMessageAddon ) {
      content = `${content} ${secondaryMessageAddon}`;
    }

    if( cefrLevel ) {
      let wordCount = 20;
      if( cefrLevel.includes('A') ) { wordCount = 12 }
      const extraContent =  `Respond to me at a ${cefrLevel} Common European Framework of Reference for Languages (CEFR) level using ${wordCount} words or less.`;

      content = `${content} ${extraContent}`;
    }


    return content;
  }

  function completeConversation() {
    setButtonDisabled(true);

    // Handle the last step
    setTimeout(() => {
      saveData(responses);
    }, 1500);

  }


  // Screen components
  let actionContent;
  let loadingElement;
  let hintElement;

  if( status === 'record' ) {

    actionContent = (
      <div>
        <AssessmentRecord
          handleTranscript={handleTranscript}
          scoreType={'conversation'}
          seconds={timerSpeak}
          messages={messages}
          extras={{cefrLevel: cefrLevel, toneLearner: 'casual'}}
        />
      </div>
    );

    if( stepCount === 1 ) {
      hintElement = (
        <div class='fade-in-on-load fade-slow'>
          {/* <p class='asess-conversation-hint'>Hint: speak as much as possible to get a good score.</p> */}
          <p class='asess-conversation-hint'>Hint: speak in complete sentences to get a good score.</p>
        </div>
      );
    } else if( stepCount === 3 ) {
      hintElement = (
        <div class='fade-in-on-load fade-slow'>
          {/* <p class='asess-conversation-hint'>Reminder: speak as much as possible to get your best score.</p> */}
          <p class='asess-conversation-hint'>Reminder: speak in complete sentences to get your best score.</p>
        </div>
      );
    }

  } else if( status === 'review' ) {
    actionContent = (
      <Review
        instruction={instructionReview}
        audioUrl={responseData.audioUrl}
        buttonFunction={() => handleNextAction(responseData)}
        seconds={timerReview}
        retryCount={retryCount}
        retryPossible={retryPossible}
        retry={retry}
      />
    );
  } else if( status === 'waiting' ) {
    actionContent = <LoadingBasic />;
  } else if( status === 'loading' ) {
    loadingElement = <LoadingBasic />;
  } else if( status === 'complete' ) {
    actionContent = (
      <div class='flex-column'>
        <p style={{marginTop: '45px', marginBottom: '30px'}}>{instructionComplete}</p>

        <ButtonSubmitRound
          value={"Continue"}
          disabled={buttonDisabled}
          loading={buttonDisabled}
          handleButtonClick={completeConversation}
        />
        <ReviewTimer seconds={timerReview} nextAction={() => completeConversation()} />
      </div>
    );
  }

  return (
    <>
      {modal}
      <h1>Conversation</h1>
      <div style={{width: '100%', marginTop: '10px', marginBottom: '30px'}}>
        <ProgressSteps
          steps={speakingTurns}
          currentStep={stepCount}
        />
      </div>

      {/* <div class='flex-column assess-glass'> */}
      <div class='flex-column assess-glass conversation-thread' ref={scrollerRef}>
        {loadingElement}
        <div style={{width: '100%'}}>

          {messages.map((message, index) =>
            <Message
              key={`conv-thread-message-${index}`}
              iconUrl={message.iconUrl}
              avatarUrl={message.avatarUrl}
              message={{role: message.role, content: message.content, display: message.display, audioUrl: message.audioUrl}}
            />
          )}

          {hintElement}
        </div>

        {actionContent}
      </div>
    </>
  );
}

export default ConversationThread;



function getNewCefrLevel(responseData) {
  let newLevel = null;

  if( responseData?.scoreTwo?.overall ) {
    const score = responseData.scoreTwo.overall.percent;
    let existingLevel = 'A2';

    if( score <= 55 ) {
      existingLevel = 'A1'
    } else if( score >= 56 && score <= 65 ) {
      existingLevel = 'A2'
    } else if( score >= 66 && score <= 75 ) {
      existingLevel = 'B1'
    } else if( score >= 76 && score <= 84 ) {
      existingLevel = 'B2'
    } else if( score >= 85 && score <= 92 ) {
      existingLevel = 'C1'
    } else if( score >= 93 ) {
      existingLevel = 'C2'
    }

    if( existingLevel ) {
      if( existingLevel === 'A1' ) {
        newLevel = 'A2';
      } else if( existingLevel === 'A2' ) {
        newLevel = 'B1';
      } else if( existingLevel === 'B1' ) {
        newLevel = 'B2';
      } else if( existingLevel === 'B2' ) {
        newLevel = 'C1';
      } else if( existingLevel === 'C1' ) {
        newLevel = 'C2';
      } else if( existingLevel === 'C2' ) {
        newLevel = 'C2';
      }
    }
  }

  return newLevel;
}



function checkForRepeat(responseObject) {
  let repeatedText = false;
  if( responseObject?.scoreTwo?.error === 'isRepeat' ) {
    return true;
  }

  return repeatedText;
}