import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";

import checkBrowser from "../../../functions/checkBrowser";
import StartHeader from "../start/startHeader";
import LoadingBasic from "../../utilities/loadingbasic";
import CodeForm from "./codeForm";
import CodeAskMic from "./codeAskMic";
import CodeMicDenied from "./codeMicDenied";

let interval;

let dummyCode;
if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
  dummyCode = '64-0000001AC';
}


function AssessmentCode(props) {

  // Define variables
  const { slug, assessCode } = useParams();
  const [micAccess, setMicAccess] = useState('unknown'); // unknown, granted, denied, prompt
  const handleSubmit = props.handleSubmit;
  const code = props.code || dummyCode;
  const handleSetCode = props.handleSetCode;
  const buttonDisabled = props.buttonDisabled;
  const loading = props.loading;


  // Component load actions
  useEffect(() => {

    const browser = checkBrowser();

    setTimeout(() => {
      if( navigator?.permissions?.query ) {
        checkMicPermission(browser);
      } else {
        // There is no permissions query, so we have to just set the permission
        // and hope their mic will work
        setMicAccess('granted');
      }
    }, 800);

    // Hack until we can figure out how get Safari to recognize
    // the on change event in navigator.permissions.query
    if( browser === 'safari' ) {
      interval = setInterval(() => safariPermissions(), 500);
    }

    // See if there is a code in the URL
    lookForProvidedcode();

  }, []);


  // Define functions
  function safariPermissions() {
    if( navigator?.permissions?.query ) {
      navigator.permissions.query({ name: 'microphone' }).then(function(permissionStatus){
        if( permissionStatus.state === 'granted' ) {
          clearInterval(interval);
          setMicAccess(permissionStatus.state);
        }
      });
    }
  }

  function checkMicPermission(browser) {

    // Firefix does not have the microphone query so we have to skip
    if( browser === 'firefox') {
      setMicAccess('granted');
      return;
    }

    navigator.permissions.query({ name: 'microphone' }).then(function(permissionStatus){
      const permission = permissionStatus.state;
      setMicAccess(permission);

      if( permission === 'prompt' ) {
        permissionStatus.onchange = () => {
          // console.log("Permission changed to", permissionStatus);
          setMicAccess(permissionStatus.state);
        }
      }
    });
  }

  function askForMicPermission() {
    navigator.mediaDevices.getUserMedia({ audio: true, video: false })
    // .then((response) => {})
    .catch((error) => {
      // Must catch the error in case they dismiss the prompt
    });
  }

  function lookForProvidedcode() {
    if( assessCode ) {
      handleSetCode(assessCode);
    } else {
      if( slug ) {
        // See if the slug is actually a code
        if( slug.length > 10 && slug.length < 18 && slug.includes('-') ) {
          handleSetCode(slug);
        }
      }
    }
  }


  // Screen components
  let bodyContent = <LoadingBasic />;

  if( micAccess === 'granted' ) {
    bodyContent = <CodeForm handleSubmit={handleSubmit} code={code} handleSetCode={handleSetCode} buttonDisabled={buttonDisabled} loading={loading} />;
  } if( micAccess === 'prompt' ) {
    bodyContent = <CodeAskMic askForMicPermission={askForMicPermission} />;
  } if( micAccess === 'denied' ) {
    bodyContent = <CodeMicDenied />;
  }


  return (
    <>
      <StartHeader big={true} />

      <div class='flex-column assess-glass-extra'>
        {bodyContent}
      </div>
    </>
  )
}

export default AssessmentCode;