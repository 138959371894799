import React from 'react';
import { useState, useEffect } from 'react';
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";

import alertMessage from '../utilities/alertMessage';
import apiCall from '../utilities/auth/apiCall';
import { getOrgGroup } from '../utilities/auth/authApi';
import { isAuthed, saveToken } from "../utilities/auth/tokenHelpers";
import StartLeftColumn from '../start/startLeftColumn';
import LoadingBasic from "../utilities/loadingbasic";
import UnauthedStartHeader from '../start/unauthedStartHeader';

import OrgRegisterForm from './orgRegisterForm';
import OrgVerify from './orgVerify';
import loadCustomCss from '../../functions/loadCustomCss';

import profileSlice from '../../store/profile';


function OrgRegister() {

  // Dispatch functions
  const dispatch = useDispatch();

  function updateField(field, value) {
    // dispatch({ type: 'updateField', payload: {field, value} });
    dispatch(profileSlice.actions.updateField({field, value}));
  }


  // Define variables
  const navigate = useNavigate();
  const { slug } = useParams();
  const [currentScreen, setCurrentScreen] = useState(<LoadingBasic />);
  const [orgData, setOrgData] = useState(null);

  let orgLogo;
  if( orgData?.logoUrl ) {
    orgLogo = orgData.logoUrl;
  }


  // Component load actions
  useEffect(() => {

    // Redirect if logged in
    const isLoggedIn = isAuthed();
    if( isLoggedIn ) {
      navigate('/dashboard');
    }

    if( orgData ) {
      setCurrentScreen(
        <OrgRegisterForm
          orgData={orgData}
          changeCurrentScreen={changeCurrentScreen}
          runLoginProcess={runLoginProcess}
          updateField={updateField}
        />
      );
    } else {
      loadOrgData();
    }

  }, [slug, orgData]);


  // Define functions
  function loadOrgData() {
    apiCall(getOrgGroup, slug).then((response) => {
      if( response.success ) {
        setOrgData(response.data);

        if( response.data.externalCssUrl ) {
          loadCustomCss(response.data.externalCssUrl);
        }

      } else {
        alertMessage(response.error, 'error');
        setCurrentScreen(
          <div class='flex-column' style={{height: '300px'}}>
            <p>Sorry, we could not find your organization.</p>
            <a href='/start' title='Link to registration page'>Click here to register</a>
          </div>
        )
      }
    });
  }

  function runLoginProcess(data) {

    let tokenData;
    if( data?.jwt ) {
      tokenData = data;
    } else {
      tokenData = {jwt: data};
    }

    saveToken(tokenData);

    let path = '/welcome-choose-pathway';
    if( data.hasActiveCourse ) {
      // Include the /group b/c we need to tell the screen to lookup
      // the profile
      // path = '/welcome-choose-topics/group';
      path = '/welcome-set-reminders/group';
    }

    setTimeout(() => {
      // navigate(`/register/${slug}/welcome`);
      navigate(path);
    }, 700);
  }

  function changeCurrentScreen(screen) {
    if( screen === 'login' ) {
      navigate('/start#login');
    } else if( screen === 'verify' ) {
      setCurrentScreen(
        <OrgVerify
          changeCurrentScreen={changeCurrentScreen}
          runLoginProcess={runLoginProcess}
        />
      );
    } else if( screen === 'register' ) {
      setCurrentScreen(
        <OrgRegisterForm
          orgData={orgData}
          changeCurrentScreen={changeCurrentScreen}
          runLoginProcess={runLoginProcess}
          updateField={updateField}
        />
      );
    }
  }


  return(
    <div class='wrapper'>
      <div class='main-body start with-start-left-column'>
        <StartLeftColumn />
        <div class='main-body-content start'>
          <div class='start-vertical-wrapper'>
            <div class='start-vertical-container'>
              <UnauthedStartHeader
                orgLogo={orgLogo}
                subTitle={orgData?.organizationName}
              />
              {currentScreen}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default OrgRegister;