import {
  IoIosCloseCircle,
  IoIosArrowDown,
  IoIosArrowUp,
  IoIosArrowBack,
  IoIosArrowForward,
  IoIosArrowDropleftCircle,
  IoIosArrowDroprightCircle,
  IoIosCheckmarkCircle,
  IoMdMenu,
  IoMdPersonAdd,
  IoIosLock,
  IoIosHelpCircleOutline,
  IoMdEye,
  IoMdEyeOff,
} from "react-icons/io";

import {
  BsBookmark,
  BsFillBookmarkFill,
  BsGem,
  BsCircleFill,
  // BsFillPatchCheckFill,
  // BsFillPostcardFill,
  BsFilterSquareFill,
  BsAwardFill,
} from "react-icons/bs";


function VectorIcon(props) {

  // Define variables
  const icon = props.icon;
  let size = props.size || '2rem';
  let color = props.color || '#2D2D2D';

  if( color === 'active' ) {
    color = '#BC0181';
  }


  let iconElement;
  if( icon === 'close' ) {
    iconElement = <IoIosCloseCircle color={color} size={size} />;
  }

  if( icon === 'bookmarkOutline' ) {
    size = '1.5rem';
    iconElement = <BsBookmark color={color} size={size} />;
  }

  if( icon === 'bookmarkFill' ) {
    size = '1.5rem';
    iconElement = <BsFillBookmarkFill color={color} size={size} />;
  }

  if( icon === 'chevronBackOutline' ) {
    iconElement = <IoIosArrowBack color={color} size={size} />;
  }

  if( icon === 'chevronBackFull' ) {
    iconElement = <IoIosArrowDropleftCircle color={color} size={size} />;
  }

  if( icon === 'chevronForwardOutline' ) {
    iconElement = <IoIosArrowForward color={color} size={size} />;
  }

  if( icon === 'chevronForwardFull' ) {
    iconElement = <IoIosArrowDroprightCircle color={color} size={size} />;
  }

  if( icon === 'gem' ) {
    iconElement = <BsGem color={color} size={size} />;
  }

  if( icon === 'stacked-nav' ) {
    iconElement = <IoMdMenu color={color} size={size} />;
  }

  if( icon === 'circleFill' ) {
    iconElement = <BsCircleFill color={color} size={size} />;
  }

  if( icon === 'checkMarkCircle' ) {
    iconElement = <IoIosCheckmarkCircle color={color} size={size} />;
  }

  if( icon === 'personAdd' ) {
    iconElement = <IoMdPersonAdd color={color} size={size} />;
  }

  if( icon === 'lock' ) {
    iconElement = <IoIosLock color={color} size={size} />;
  }

  if( icon === 'help' ) {
    iconElement = <IoIosHelpCircleOutline color={color} size={size} />;
  }

  if( icon === 'badge' ) {
    iconElement = <BsAwardFill color={color} size={size} />;
  }

  if( icon === 'certificate' ) {
    iconElement = <BsFilterSquareFill color={color} size={size} />;
  }

  if( icon === 'eye' ) {
    iconElement = <IoMdEye color={color} size={size} />;
  }

  if( icon === 'eyeOff' ) {
    iconElement = <IoMdEyeOff color={color} size={size} />;
  }

  if( icon === 'chevronDown' ) {
    iconElement = <IoIosArrowDown color={color} size={size} />;
  }

  if( icon === 'chevronUp' ) {
    iconElement = <IoIosArrowUp color={color} size={size} />;
  }




  return (
    <div class='vector-icon' style={{height: size}}>{iconElement}</div>
  );
}

export default VectorIcon;