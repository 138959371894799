import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { validateAuthCode } from "../utilities/auth/authApi";
import { saveToken } from "../utilities/auth/tokenHelpers";
import { reportError } from "../../functions/sentry";

import alertMessage from "../utilities/alertMessage";
import LoadingBasic from "../utilities/loadingbasic";


function AssessmentAutoLoginScreen() {

  // Define variables
  const navigate = useNavigate();
  let userCode;
  let authCode;

  if( URLSearchParams ) {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);

    userCode = urlParams.get('code');
    authCode = urlParams.get('auth_code');
  }


  // Component load actions
  useEffect(() => {
    validateAuthCode({auth_code: authCode}).then((response) => {
      if( response?.data?.auth ) {
        let path = `/assessments/start/${userCode}`;

        // Log the learner in
        saveToken(response.data);
        navigate(path);

      } else {
        alertMessage('Sorry, there was an error logging you in. Please try again or contact support@flowspeak.io for help', 'error');
      }
    }).catch((error) => {
      alertMessage('Sorry, there was an error logging you in. Please try again or contact support@flowspeak.io for help', 'error');
      reportError(error);
    });
  }, []);


  return (
    <div class='flex-column assess-wrapper'>
      <div class='flex-column assess-continer' style={{justifyContent: 'center', height: '100vh'}}>
        <LoadingBasic />
      </div>
      <div class='assess-background'></div>
    </div>
  );
}

export default AssessmentAutoLoginScreen;